import { ObjectHash } from '../queryString'

export const DEFAULT_ACTIVE_CURRENCY = 'USD'
export const TILE_WIDTH = 240

export const CURRENCIES_LAYOUT: Record<string, string[][]> = {
  USD: [
    ['BTCUSD', 'ETHUSD', 'SOLUSD', 'BNBUSD', 'XRPUSD'],
    ['DOGEUSD', 'ADAUSD', 'TRXUSD', 'AVAXUSD', 'XLMUSD'],
    ['DOTUSD', 'LINKUSD', 'BCHUSD', 'SUIUSD', 'NEARUSD'],
    ['LTCUSD', 'APTUSD', 'EGLDUSD', 'OPUSD', 'AAVEUSD'],
  ],
  USDT: [
    ['BTCUSDT', 'ETHUSDT', 'SOLUSDT', 'BNBUSDT', 'XRPUSDT'],
    ['DOGEUSDT', 'ADAUSDT', 'TRXUSDT', 'AVAXUSDT', 'XLMUSDT'],
    ['DOTUSDT', 'LINKUSDT', 'BCHUSDT', 'SUIUSDT', 'NEARUSDT'],
    ['LTCUSDT', 'APTUSDT', 'EGLDUSDT', 'OPUSDT', 'SUSDT'],
  ],
  EUR: [
    ['BTCEUR', 'ETHEUR', 'SOLEUR', 'BNBEUR', 'XRPEUR'],
    ['DOGEEUR', 'ADAEUR', 'TRXEUR', 'AVAXEUR', 'XLMEUR'],
    ['DOTEUR', 'LINKEUR', 'BCHEUR', 'SUIEUR', 'NEAREUR'],
    ['LTCEUR', 'APTEUR', 'EGLDEUR', 'OPEUR', 'SEUR'],
  ],
  BTC: [
    ['BTCUSD', 'ETHBTC', 'SOLBTC', 'BNBBTC', 'XRPBTC'],
    ['DOGEBTC', 'ADABTC', 'TRXBTC', 'AVAXBTC', 'XLMBTC'],
    ['DOTBTC', 'LINKBTC', 'BCHBTC', 'SUIBTC', 'NEARBTC'],
    ['LTCBTC', 'APTBTC', 'EGLDBTC', 'OPBTC', 'SBTC'],
  ],
}

// NOTE: all tickers that are not visible at main 'crypto.ashx' but
// we wanna keep them displayed as sparkline in the detail of ticker
export const TICKERS_NOT_IN_DASHBOARD: Record<string, string[]> = {
  USDT: ['AAVEUSDT', 'TRUMPUSDT'],
  BTC: ['AAVEBTC'],
}

export const CURRENCIES_LIST = Object.keys(CURRENCIES_LAYOUT)

export const CRYPTO_TICKERS = [
  ...Object.values(CURRENCIES_LAYOUT).flat(2),
  ...Object.values(TICKERS_NOT_IN_DASHBOARD).flat(1),
]

export function getCurrency(ticker: string): string {
  if (ticker.endsWith('USD')) {
    return 'USD'
  } else if (ticker.endsWith('USDT')) {
    return 'USDT'
  } else if (ticker.endsWith('EUR')) {
    return 'EUR'
  } else if (ticker.endsWith('BTC')) {
    return 'BTC'
  }
  return DEFAULT_ACTIVE_CURRENCY
}

export function getActiveCurrency(queryString: ObjectHash<string, string>) {
  const activeCurrency = (queryString['c'] || DEFAULT_ACTIVE_CURRENCY).toUpperCase()
  return CURRENCIES_LIST.includes(activeCurrency) ? activeCurrency : DEFAULT_ACTIVE_CURRENCY
}

export function getActiveCurrencySparklineTickers(activeCurrency: string) {
  return [...CURRENCIES_LAYOUT[activeCurrency].flat(1), ...(TICKERS_NOT_IN_DASHBOARD[activeCurrency] ?? [])]
}

export function getCurrencyLongName(currency: string): string {
  switch (currency.toLowerCase()) {
    case 'btc':
      return 'Bitcoin'
    case 'eth':
      return 'Ethereum'
    case 'bnb':
      return 'Binance Coin'
    case 'sol':
      return 'Solana'
    case 'xrp':
      return 'Ripple'
    case 'doge':
      return 'Dogecoin'
    case 'ada':
      return 'Cardano'
    case 'trx':
      return 'TRON'
    case 'avax':
      return 'Avalanche'
    case 'dot':
      return 'Polkadot'
    case 'bch':
      return 'Bitcoin Cash'
    case 'near':
      return 'NEAR Protocol'
    case 'ltc':
      return 'Litecoin'
    case 'icp':
      return 'Internet Computer'
    case 'apt':
      return 'Aptos'
    case 'xlm':
      return 'Stellar'
    case 'link':
      return 'Chainlink'
    case 'shib':
      return 'Shiba Inu'
    case 'etc':
      return 'Ethereum Classic'
    case 'uni':
      return 'Uniswap'
    case 'fet':
      return 'Fetch.ai'
    case 'egld':
      return 'MultiversX'
    case 's':
      return 'Sonic'
    case 'op':
      return 'Optimism'
    case 'sui':
      return 'Sui'
    case 'aave':
      return 'Aave'
    case 'trump':
      return 'Trump'
    case 'usd':
      return 'US Dollar'
    case 'eur':
      return 'Euro'
    case 'usdt':
      return 'Tether'
    default:
      return currency
  }
}
