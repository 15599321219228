// Keep in sync with the copy in Charts repo - app/constants/common.ts
export enum TIMEFRAME {
  i1 = 'i1',
  i2 = 'i2',
  i3 = 'i3',
  i5 = 'i5',
  i10 = 'i10',
  i15 = 'i15',
  i30 = 'i30',
  h = 'h',
  h2 = 'h2',
  h4 = 'h4',
  d = 'd',
  w = 'w',
  m = 'm',
}

export enum SpecificChartFunctionality {
  default = 'default',
  quotePage = 'quotePage',
  quotePerf = 'quotePerf',
  quoteFinancials = 'quoteFinancials',
  chartPage = 'chartPage',
  smallIndex = 'smallIndex',
  offScreen = 'offScreen',
  forexPage = 'forexPage',
  futuresPage = 'futuresPage',
  cryptoPage = 'cryptoPage',
}

// @TODO: Reuse across the app (website & charts)
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const FX_CRYPTO_FUT_REFRESH_MS = 30 * 1000
