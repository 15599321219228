import * as Sentry from '@sentry/browser'

import { ThrowableError } from '../../../main/services/api'
import { getSentryOptions } from './constants'

if ('FinvizSentrySettings' in window && !('sentryDisabled' in window)) {
  const sentryOptions = getSentryOptions(window)
  Sentry.init({
    ...sentryOptions,
    beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
      const originalError = hint.originalException as Error | null
      const exception = event.exception?.values?.[0]
      const errorFrames = exception?.stacktrace?.frames ?? []

      const mechanism = exception?.mechanism?.type ?? hint.mechanism?.type
      const message = originalError?.message ?? exception?.value

      /**
       * Ignore scripts matching `ignoreStack`.
       * - If pattern is string, only the script url is matched. In case of `http.client`, the url is matched
       * - If pattern is array, both url and and error message must match
       */
      const isIgnoredError = sentryOptions.ignoreStack.some((ignorePattern) => {
        const [ignoreSource, ignoreMessage] = Array.isArray(ignorePattern) ? ignorePattern : [ignorePattern]
        const isIgnoredScript =
          errorFrames.some((frame) => frame?.filename?.includes(ignoreSource)) ||
          (mechanism === 'http.client' && event.request?.url?.includes(ignoreSource))

        if (!ignoreMessage) return isIgnoredScript

        return message?.match(ignoreMessage)
      })

      if (isIgnoredError) return null

      // Completely ignore 524 errors, track other network errors as `info` if the status is 520+
      const statusCode =
        originalError instanceof ThrowableError ? originalError.status : (event.contexts?.response?.status_code ?? -1)
      if (statusCode === 500) {
        return { ...event, level: 'error', fingerprint: ['500'] }
      } else if (statusCode > 500 && statusCode < 520) {
        return { ...event, level: 'info', fingerprint: ['500+'] }
      } else if (statusCode > 520) {
        // Completely ignore 524 errors
        if (statusCode === 524) return null
        return { ...event, level: 'info', fingerprint: ['520+'] }
      }

      return event
    },
  })

  if (FinvizSentrySettings.id > 0) {
    Sentry.setUser({ id: String(FinvizSentrySettings.id) })
  }

  try {
    Sentry.setTags({
      dpr: window.devicePixelRatio,
      redesign: window.FinvizSettings?.hasRedesignEnabled,
      dark: window.FinvizSettings?.hasDarkTheme,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    })
  } catch {}
}

window['Sentry'] = {
  captureException: Sentry.captureException,
  captureMessage: Sentry.captureMessage,
  withScope: Sentry.withScope,
  getCurrentScope: Sentry.getCurrentScope,
}
